.background-map {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.leaflet-tooltip-tiny {
    z-index: 1000 !important;
    margin-top: -10px;
    padding: 0;
    border: 0;
}

.leaflet-tooltip-opaque {
    opacity: .5 !important;
}

.leaflet-tooltip-hovered {
    opacity: 1 !important;
    z-index: 9999 !important;
    animation: tooltipAnimation 0.3s 2 forwards ease-in-out;
}

@keyframes tooltipAnimation {
    from {
        margin-top: -10px;
    }
    50% {
        margin-top: -20px;
    }
    to {
        margin-top: -10px;
    }
}
