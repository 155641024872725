.App {
  display: flex;
  flex-flow: column;
  height: 100vh;
  width: 100vw;
  color: white;
}

.vinka-containers {
  overflow: auto;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  height: 100%;
  padding: .5rem;
}

.vinka-containers-column {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-height: 100%;
}

.vinka-column-head {
  flex-shrink: 0;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: .5em;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: .5em;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: .5em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.inline-link, .inline-link:hover {
  color: inherit;
}

.inline-link i {
  font-size: 75%;
}
