.eezery {
    $background-color: rgba(30, 33, 78, .9);
    $border-radius: .5em;
    $orange: #db5f00;

    .btn {
        border-radius: $border-radius;
    }

    .vinka-brand {
        background-image: url('/logos/eezery.png');
    }

    .navbar {
        background-color: $background-color;
    }

    .nav-link {
        text-transform: uppercase;

        &:hover {
            color: $orange !important;
        }
    }

    // .side-panel {
    //     border-radius: $border-radius;
    //     background-color: $background-color;
    // }

    // /* width */
    // ::-webkit-scrollbar {
    //     border-radius: $border-radius;
    // }

    // /* Track */
    // ::-webkit-scrollbar-track {
    //     background: rgba(235, 239, 241, .4);
    //     border-radius: $border-radius;
    // }

    // /* Handle */
    // ::-webkit-scrollbar-thumb {
    //     background: $orange;
    //     border-radius: $border-radius;
    // }

    // /* Handle on hover */
    // ::-webkit-scrollbar-thumb:hover {
    //     background: #be4801;
    // }

    // .sort-table tbody tr {
    //     &:nth-child(odd) {
    //         background-color: rgba(93, 95, 131, .7);
    //     }

    //     &:hover {
    //         background-color: rgba(219, 95, 0, .7) !important;
    //     }
    // }

    // .batch-page {
    //     .dropzone {
    //         background-color: rgba(93, 95, 131, .7);
    //     }

    //     .dropzone-active {
    //         border-color: $orange;
    //         color: #ffffff;
    //     }
    // }
}
