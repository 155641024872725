.trip-panel {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.trip-panel .card {
    background-color: transparent;
}

.trip-panel .card-header {
    background-color: rgba(73, 81, 89, 0.6);
    border-bottom-color: #ffffff;
    cursor: pointer;
}

.trip-panel .card-header:hover {
    background-color: rgba(45, 45, 45, 0.72);
}

.trip-panel .accordion > .card:first-of-type:last-of-type {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-radius: 0.25rem;
}

.trip-panel .nav-tabs {
    justify-content: flex-end;
}

.trip-panel .nav-tabs .nav-link.disabled {
    padding: 0;
    margin-right: auto;
}

.trip-panel .nav-tabs .nav-link.disabled h2 {
    line-height: 1;
    margin: 0;
    color: #ffffff;
}

.trip-panel .nav-tabs .nav-link {
    color: #ffffff;
}

.trip-panel .nav-tabs .nav-link.active {
    color: #495057;
}
