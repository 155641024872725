.navbar {
    position: relative;
    left: 0;
    top: 0;
    z-index: 11;
    width: 100%;
    background-color: rgba(0,0,0, .72);
}

.nav-link {
    color: #ffffff;
    transition: all 0.1s linear 0.01s;
}

.vinka-brand {
    padding-left: 130px;
    background: url('/loki/vinka-logo-w-smaller.png') no-repeat left top;
    font-weight: bold;
}

.vinka-settings .dropdown-toggle {
    padding: 0;
    font-size: x-large;
    color: #fff;
}

.vinka-settings .dropdown-menu {
    left: auto !important;
    right: 0 !important;;
    top: auto !important;
    bottom: 0 !important;
    transform: translateY(100%) !important;
}
