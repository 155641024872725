.gantt {
    user-select: none;
    position: relative;
    z-index: 1;
    height: 3rem;
    margin: 0.5rem 2rem 3rem;
    opacity: .8;
    flex-shrink: 0;
}

.gantt:hover {
    opacity: 1;
}

.gantt:focus {
    outline: none;
    opacity: 1;
}

.gantt:focus .gantt-events {
    box-shadow: 0 0 10px rgba(0,0,0,1);
}

.gantt-events {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, .72);
}

.gantt-event {
    overflow: hidden;
    position: absolute;
    top: 0; /*50%*/
    height: 94%;
    min-width: 4px;
    opacity: .72;
    transition: all 0.1s linear 0.01s;/*transition: height 0.2s ease-out 0.01s;*/
    /*transform: translateY(-50%);*/
    color: #000;
    cursor: pointer;
}

.gantt-event:hover {
    z-index: 3;
    opacity: .9;
    /*height: 140%;*/
}


.gantt-event-shift {
    z-index: 1;
    background: #b2b2b2;
}

.gantt-event-route {
    z-index: 2;
    background: #9ed973;
}

.gantt-event-break {
    z-index: 2;
    background: #ff8f80;
}

.gantt-event-offer {
    z-index: 3;
    background: #feea34;
}

.gantt-event-offer-rejected {
    background-image: repeating-linear-gradient(-45deg, #c59d02, #c59d02 4px, transparent 4px, transparent 10px);
}
.gantt-event-offer.viper-offer-rejected-by-driver {
    background-image: repeating-linear-gradient(-45deg, #c59d02, #c59d02 4px, transparent 4px, transparent 10px);
}
.gantt-event-offer.vg-vehicle-not-found {
    background-image: repeating-linear-gradient(-45deg, #c59d02, #c59d02 4px, transparent 4px, transparent 10px);
}

.gantt-mouse-tip {
    position: absolute;
    left: 0;
    bottom: 100%;
    z-index: 0;
    display: none;
    margin-bottom: .5rem;
    padding: .5rem 1rem;
    transform: translate(-50%);
    background-color: rgba(0,0,0, .72);
    text-align: center;
    white-space: nowrap;
}

.gantt-mouse-tip:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    height: 0;
    width: 0;
    border: .5rem solid transparent;
    border-top-color: rgba(0,0,0, .72);
    border-bottom: 0;
    transform: translate(-50%);
}

.gantt-mouse-tip-left {

}

.gantt-timeline-pointer {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 5;
    width: 1rem;
    height: 1rem;
    background: #fff;
    transform: translateX(-50%);
    opacity: .9;
    /*transition: left 0.1s linear 0.01s;*/
}

.gantt-timeline-pointer:hover {
    opacity: 1;
}

.gantt-timeline-pointer:before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 100%;
    height: 0;
    width: 0;
    border: .5rem solid transparent;
    border-bottom-color: #fff;
    border-top: 0;
    transform: translate(-50%);
}

.gantt-tick {
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    margin-top: 1rem;
    padding: 0 3px;
    border-radius: 3px;
    background-color: rgba(0,0,0, .72);
    color: #fff;
}
.gantt-tick:before {
    content: '';
    position: absolute;
    height: 1rem;
    border-left: 1px solid #000;
    top: -1rem;
    left: 50%;
    transform: translateX(-50%);
}

.gantt-ping-range {
    position: absolute;
    bottom: 0;
    z-index: 1;
    height: 4%;
    background-color: #66ff00;
}

.is-grabbable {
    cursor: grab !important;
}

.is-grabbed {
    cursor: grabbing !important;
}

.is-grabbed .gantt-event {
    cursor: grabbing;
    transition: none;
}
