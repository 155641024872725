.itinerary-table {
    width: 100%;
}

.itinerary-table tr {
    border-bottom: 1px lightgray solid;
}

.itinerary-table tbody tr:nth-child(odd) {
    background-color: rgba(45, 45, 45, 0.72);
}

.itinerary-table tr.hovered { /*.itinerary-table tbody tr:hover, */
    background-color: rgba(73, 81, 89, 0.6) !important;
}

.itinerary-table tr.expanded {
    border-bottom: 0;
    background-color: rgba(0, 0, 0, 0.6) !important;
}

.itinerary-table tr.expanded.details {
    border-bottom: 1px lightgray solid;
}

.itinerary-table td, .itinerary-table th {
    vertical-align: top;
    padding: 1px 3px;
}

.itinerary-table th {
    white-space: nowrap;
}

.itinerary-date-row {
    font-weight: bold;
    background-color: rgba(73, 81, 89, 0.6) !important;
}
