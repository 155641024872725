.side-panel {
    z-index: 1;
    overflow-y: auto;
    pointer-events: auto;
    width: 100%;
    max-width: 500px;
    padding: 1em;
    border-radius: .5em;
    background-color: rgba(0,0,0, .72);
    font-size: 0.8em;
}

.side-panel + .side-panel {
    margin-top: .5rem;
}

.side-panel-full {
    max-width: none;
}

.side-panel-visible {
    z-index: 2;
    overflow-y: visible;
}
