.info-table {
    width: 100%;
}

.info-table-header {
    white-space: nowrap;
}

.info-table tr:hover {
    background-color: rgba(73, 81, 89, 0.6) !important;
}

.info-table-highlight {
    background-color: rgba(139, 121, 42, 0.6) !important;
}
