.sort-table {
    width: 100%;
}

.sort-table tr {
    border-bottom: 1px lightgray solid;
}
.sort-table tbody tr:last-child {
    border-bottom: 0;
}

.sort-table tbody tr:nth-child(odd) {
    background-color: rgba(45, 45, 45, 0.72);
}

.sort-table tbody tr:hover { /*.itinerary-table tbody tr:hover, */
    background-color: rgba(73, 81, 89, 0.6) !important;
}

.sort-table td, .sort-table th {
    vertical-align: top;
    padding: 1px 3px;
    border-right: 1px lightgray solid;
}
.sort-table td:last-child, .sort-table th:last-child {
    border-right: 0;
}

.sort-table th {
    white-space: nowrap;
    text-align: center;
}
