.grid-table tr {
    border-bottom: 1px lightgray solid;
}
.grid-table tr:last-child {
    border-bottom: none;
}

.grid-table {
    width: 100%;
}

.grid-table td {
    vertical-align: top;
    padding: 1px 3px;
    border-right: 1px lightgray solid;
}

.grid-table td.header {
    font-weight: 700;
}

.grid-table td:last-child {
    border-right: none;
}

/*.grid-table tr:nth-child(even) td:nth-child(odd) {
  background-color: rgba(73, 81, 89, 0.6);
}

.grid-table tr:nth-child(odd) td:nth-child(even) {
  background-color: rgba(73, 81, 89, 0.6);
}*/
